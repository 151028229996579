<template>
	<div class="mt-3">
		<div  v-if="isReady && printers.length">
			<b-form v-if="isReady && printers.length" @submit.prevent="printLabel">
				<div class="row mb-3">
					<div class="col-sm-5 m-auto">
						<div class="small">
							<span v-if="label_printer" class="text-black-50">Label:</span> {{label_printer}} 
							<span v-if="barcode_printer" class="text-black-50">Barcode:</span> {{barcode_printer}}
							<span class="float-right" title="Edit Printer Settings" v-b-tooltip.hover>
								<b-icon-pencil-square class="m-1 pointer text-success" @click="isReady=false"></b-icon-pencil-square>
							</span>
						</div>
						<div class="input-group mb-3">
							<b-form-input type="text" tabindex="1" :disabled="errormsg ? true : false" autocomplete="off" ref="lm_or_hawm" @keydown.tab="printLabel" autofocus v-model="lm_awb" placeholder="Enter LM-AWB"></b-form-input>
						</div>
						<div v-if="errormsg" class="alert alert-danger"> {{ errormsg }} </div>
						
						<div v-if="hawb">
							<!-- Print Bagcode -->
							<div id="capture">
								<VueBarcode :value="hawb" height="75" width="1">
									Failed to generate barcode.
								</VueBarcode>
							</div>
						</div>

						<div v-if="label_url">

							<!-- Print Label -->
							<div id="capture-label">

								<div v-if="filetype == 'dpdLabel'">
									<a :href="label_url" :download="filename">Download Label</a> 
								</div>

								<div v-else-if="filetype == 'pdf'">
									<a :href="label_url" :download="filename">Download Label</a> 
								</div>

								<img v-else :src="label_url" alt="label">

							</div>

						</div>
					</div>
				</div>
			</b-form>
		</div>
		<div v-else>
			<AssignPrinter :printers="printers" v-if="printers" />
		</div>

	</div>
</template>

<script>

import VueBarcode from 'vue-barcode'
import html2canvas from 'html2canvas'
import * as JSPM from 'jsprintmanager'
import AssignPrinter from '@/components/AssignPrinter.vue'

export default {
	name: "PrintLabel",
	title: "Print Label",
	data() {
		return {
			updated: false,
			lm_awb: '',
			label_url: '',
			filetype: 'png',
			filename: '',
			hawb: '',
			errormsg: '',
			printers: [],
			isReady: false,
			dpdLabel_url: '',
		}
	},
	components: {
		VueBarcode,
		AssignPrinter
	},
	mounted() {
		this.onInit();
		if((this.label_printer || this.barcode_printer)) {
			this.isReady = true
		}
	},
	methods: {

		onInit: function () {
			var _this = this;
			JSPM.JSPrintManager.license_url = `${process.env.VUE_APP_API_ENDPOINT}/wh-license`;
			JSPM.JSPrintManager.auto_reconnect = true;
			JSPM.JSPrintManager.start();
			JSPM.JSPrintManager.WS.onStatusChanged = function () {
				_this.getPrinters().then((p) => {
					_this.printers = p;
					_this.$nextTick();
				});
			};
		},
		getPrinters: function () {
			return new Promise((ok, err) => {
				let printers = [null];
				if (JSPM.JSPrintManager.websocket_status == JSPM.WSStatus.Open) {
					this.errormsg = ''
					JSPM.JSPrintManager.getPrinters()
						.then(function (myPrinters) {
							printers.push(...myPrinters);
							ok(printers);
						})
						.catch((e) => err(e));
				} else {
					console.warn("JSPM WS not open");
					this.errormsg = 'Please start JSPrintManager application.'
					ok(printers);
				}
			});
		},
		async printLabel() {
			try {
				const res = await this.axios.post(`/lmLabel`, {lmAwb: this.lm_awb})

				if(res.data.success) {

					console.log('res.data', res.data);

					this.dpdLabel_url = res.data?.dpdLabelPath;
					this.label_url = res.data?.image
					this.filename = res.data?.name
					this.hawb = res.data?.hawb


					if(this.hawb) {
						setTimeout(() => {
							this.printBarcode(res.data.hawb)
						});
					}

					// Print DPD label
					if(this.dpdLabel_url) {
						this.filetype = 'dpdLabel'
					}
					else if(this.filename.includes('.pdf')) {
						this.filetype = 'pdf'
					}
					else {
						this.filetype = 'png'
					}	

					setTimeout(() => {
						this.printLabelImage()
					});
					
				}
				else {
					this.popToast('Failed!', res.data.message, 'danger')
				}

				this.lm_awb = '';

			}
			catch (err) {
				this.lm_awb = ''
				console.error(err)
			}

		},

		jspmWSStatus() {
			if (JSPM.JSPrintManager.websocket_status == JSPM.WSStatus.Open) {
				return true;
			}else if (JSPM.JSPrintManager.websocket_status == JSPM.WSStatus.Closed) {
				alert('JSPrintManager (JSPM) is not installed or not running in the print server! Download and install JSPM Client App from https://neodynamic.com/downloads/jspm');
				this.isReady = false
				return false;
			}
			else if (JSPM.JSPrintManager.websocket_status == JSPM.WSStatus.Blocked) {
				alert('JSPM has blocked this website!');
				return false;
			}
		},

		async printLabelImage() {

			if(this.jspmWSStatus()) {

				if(this.filetype == 'dpdLabel') {

					if(this.$store.getters.global.label_printer) {

						let cpj = new JSPM.ClientPrintJob();
						cpj.clientPrinter = new JSPM.InstalledPrinter(this.$store.getters.global.label_printer);

						console.log('this.dpdLabel_url', this.dpdLabel_url);

						cpj.printerCommands = this.dpdLabel_url;

						//Send print job to printer!
						cpj.sendToClient();

					}

				}
				else if(this.filetype == 'pdf') {

					if(this.$store.getters.global.label_printer) {
						let cpj = new JSPM.ClientPrintJob();
						cpj.clientPrinter = new JSPM.InstalledPrinter(this.$store.getters.global.label_printer);

						//Set content to print... in this sample, a pdf file
						const myPdfFile = new JSPM.PrintFilePDF(this.label_url, JSPM.FileSourceType.URL, this.filename, 1);

						//add file to print job
						cpj.files.push(myPdfFile);

						//Send print job to printer!
						cpj.sendToClient();
					}
					
				}
				else {

					html2canvas(document.querySelector("#capture-label")).then(canvas => {
						// document.body.appendChild(canvas)
						if(this.$store.getters.global.label_printer) {
							let cpj = new JSPM.ClientPrintJob();
							cpj.clientPrinter = new JSPM.InstalledPrinter(this.$store.getters.global.label_printer);

							//Set content to print... 
							const b64Prefix = "data:image/png;base64,";
							const imgBase64DataUri = canvas.toDataURL("image/png");
							const imgBase64Content = imgBase64DataUri.substring(b64Prefix.length, imgBase64DataUri.length);
							const myImageFile = new JSPM.PrintFile(imgBase64Content, JSPM.FileSourceType.Base64, 'labelToPrint.png', 1);
							
							//add file to print job
							cpj.files.push(myImageFile);
							cpj.sendToClient();
						}
					});

				}
				this.$refs.lm_or_hawm.focus()
			}
		},

		async printBarcode() {
			if(this.jspmWSStatus()) {
				html2canvas(document.querySelector("#capture")).then(canvas => {
					// document.body.appendChild(canvas)
					if(this.$store.getters.global.barcode_printer) {
						let cpj = new JSPM.ClientPrintJob();
						cpj.clientPrinter = new JSPM.InstalledPrinter(this.$store.getters.global.barcode_printer);

						//Set content to print... 
						const b64Prefix = "data:image/png;base64,";
						const imgBase64DataUri = canvas.toDataURL("image/png");
						const imgBase64Content = imgBase64DataUri.substring(b64Prefix.length, imgBase64DataUri.length);
						const myImageFile = new JSPM.PrintFile(imgBase64Content, JSPM.FileSourceType.Base64, 'barcodeToPrint.png', 1);
						
						//add file to print job
						cpj.files.push(myImageFile);
						cpj.sendToClient();
					}
				});
				this.$refs.lm_or_hawm.focus()
			}
		},
	},
	computed: {
		label_printer() {
			return this.$store.getters.global.label_printer || null
		},
		barcode_printer() {
			return this.$store.getters.global.barcode_printer || null
		},
	}
}
</script>

<style>
	img, .pdf {
		width: 100%;
	}
	.img {
		width: 100%;
		height: 450px;
		overflow: auto;
	}
</style>